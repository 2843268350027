

import React from 'react';

const EducationSvg = () => {
  return (
    <div className="svgImage">
      <svg viewBox="0 0 512 512" >
        <g>
          <g>
            <path d="M472.004,0c-0.001,0-0.003,0-0.004,0H8C3.583-0.001,0.001,3.579,0,7.996C0,7.997,0,7.999,0,8v160h16V16h448v176H184v16
              h288c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004V8C480.001,3.583,476.421,0.001,472.004,0z"/>
          </g>
        </g>
        <g>
          <g>
            <rect x="264" y="40" width="160" height="16"/>
          </g>
        </g>
        <g>
          <g>
            <rect x="288" y="88" width="160" height="16"/>
          </g>
        </g>
        <g>
          <g>
            <rect x="256" y="136" width="160" height="16"/>
          </g>
        </g>
        <g>
          <g>
            <path d="M255.362,68.974c-5.477-13.165-20.589-19.397-33.753-13.92c-0.225,0.094-0.449,0.19-0.671,0.29l-64.868,33.65
              C158.628,83.692,159.971,77.886,160,72c0-22.091-17.909-40-40-40S80,49.909,80,72c0.03,8.683,2.913,17.116,8.205,24h-0.333
              c-22.708,0.152-42.979,14.269-50.996,35.516L15.52,205.125c-5.084,13.206,1.5,28.034,14.707,33.118
              c6.022,2.318,12.697,2.279,18.692-0.11c6.888-2.775,12.248-8.379,14.715-15.383L72,196.125v115.783
              c-6.884-5.291-15.317-8.174-24-8.205c-22.058-0.033-39.967,17.822-40,39.881c-0.016,10.803,4.344,21.151,12.087,28.685
              C7.799,378.547,0.048,391.163,0,404.961V472c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h80
              c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004v-67.039c-0.039-11.339-5.307-22.025-14.276-28.961h60.553
              c-8.97,6.936-14.238,17.622-14.276,28.961V472c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h80
              c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004v-67.039c-0.048-13.798-7.799-26.414-20.087-32.692
              c15.795-15.417,16.102-40.72,0.685-56.515c-9.494-9.727-23.281-13.962-36.598-11.242V140.859l73.441-38.133
              C254.606,97.25,260.838,82.138,255.362,68.974z M120,48c13.255,0,24,10.745,24,24s-10.745,24-24,24
              c-13.249-0.014-23.986-10.751-24-24C96,58.745,106.745,48,120,48z M48,319.703c13.255,0,24,10.745,24,24s-10.745,24-24,24
              s-24-10.745-24-24C24.014,330.454,34.751,319.718,48,319.703z M80,464H16v-59.039c0.016-11.571,9.393-20.947,20.965-20.961h22.07
              c11.571,0.014,20.949,9.39,20.965,20.961V464z M208,404.961V464h-64v-59.039c0.016-11.571,9.393-20.947,20.965-20.961h22.07
              C198.607,384.014,207.984,393.39,208,404.961z M176,319.703c13.249,0.014,23.986,10.751,24,24c0,13.255-10.745,24-24,24
              s-24-10.745-24-24S162.745,319.703,176,319.703z M240.723,82.32c-1.071,2.748-3.27,4.905-6.039,5.922l-78.367,40.656
              C153.665,130.274,152,133.013,152,136v175.908c-14.985,11.08-20.176,31.133-12.448,48.092H128V224h-16v136H84.448
              c2.276-5.03,3.475-10.48,3.522-16H88V144c-0.001-4.419-3.584-8-8.002-7.999c-3.493,0.001-6.582,2.268-7.63,5.601L48.48,217.617
              c-1.81,4.992-7.325,7.572-12.317,5.761c-0.35-0.127-0.693-0.274-1.027-0.441c-4.615-2.385-6.574-7.953-4.468-12.703l21.355-73.609
              c5.759-14.79,19.976-24.556,35.848-24.625h56.645c1.282,0.002,2.546-0.307,3.684-0.898l79.512-41.273
              c4.77-1.99,10.268,0.046,12.59,4.664C241.533,76.927,241.686,79.767,240.723,82.32z"/>
          </g>
        </g>
        <g>
          <g>
            <path d="M331.913,372.269c15.823-15.416,16.153-40.741,0.737-56.564c-15.416-15.823-40.741-16.153-56.564-0.737
              c-15.823,15.416-16.153,40.741-0.737,56.564c0.243,0.249,0.489,0.495,0.738,0.737c-12.287,6.278-20.039,18.894-20.087,32.692V472
              c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h80c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004v-67.039
              C351.952,391.163,344.201,378.547,331.913,372.269z M304,319.703c13.255,0,24,10.745,24,24s-10.745,24-24,24s-24-10.745-24-24
              C280.014,330.454,290.751,319.718,304,319.703z M336,464h-64v-59.039c0.016-11.571,9.393-20.947,20.965-20.961h22.07
              c11.571,0.014,20.949,9.39,20.965,20.961V464z"/>
          </g>
        </g>
        <g>
          <g>
            <path d="M459.913,372.269c15.823-15.416,16.153-40.741,0.737-56.564c-15.416-15.823-40.741-16.153-56.564-0.737
              c-15.823,15.416-16.153,40.741-0.737,56.564c0.243,0.249,0.489,0.495,0.738,0.737c-12.287,6.278-20.039,18.894-20.087,32.692V472
              c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h80c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004v-67.039
              C479.952,391.163,472.201,378.547,459.913,372.269z M432,319.703c13.255,0,24,10.745,24,24s-10.745,24-24,24s-24-10.745-24-24
              C408.014,330.454,418.751,319.718,432,319.703z M464,464h-64v-59.039c0.016-11.571,9.393-20.947,20.965-20.961h22.07
              c11.571,0.014,20.949,9.39,20.965,20.961V464z"/>
          </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
      </svg>
    </div>
  );
}
export default EducationSvg;