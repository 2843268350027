

import React from 'react';

const MoneySvg = () => {
  return (
    <div className="svgImage">
      <svg viewBox="0 0 512 512">
      <g>
        <g>
          <path d="M406.872,160.017c-0.005,0-0.011,0-0.016,0h-400c-3.782-0.004-6.852,3.058-6.856,6.84c0,0.005,0,0.011,0,0.016v192
            c-0.004,3.782,3.058,6.852,6.84,6.856c0.005,0,0.011,0,0.016,0h272c3.786,0,6.856-3.07,6.856-6.856
            c0-3.786-3.07-6.856-6.856-6.856H13.712V173.729H400v17.144c-0.004,3.782,3.058,6.852,6.84,6.856c0.005,0,0.011,0,0.016,0
            c3.782,0.004,6.852-3.058,6.856-6.84c0-0.005,0-0.011,0-0.016v-24C413.716,163.091,410.654,160.022,406.872,160.017z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M360.36,128.185l-320-72c-1.776-0.397-3.637-0.075-5.176,0.896c-1.537,0.979-2.624,2.526-3.024,4.304l-16,72
            c-0.822,3.698,1.51,7.362,5.208,8.184c3.698,0.822,7.362-1.51,8.184-5.208l14.504-65.288l313.296,70.488
            c0.496,0.115,1.003,0.172,1.512,0.168c3.786-0.007,6.85-3.082,6.844-6.868C365.702,131.66,363.482,128.89,360.36,128.185z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M321.504,88.513l-192-80c-3.337-1.391-7.182,0.038-8.8,3.272l-16,32c-1.807,3.342-0.563,7.517,2.78,9.324
            c3.342,1.807,7.517,0.563,9.324-2.78c0.071-0.131,0.138-0.265,0.2-0.401v0.016l13.128-26.272l186.072,77.528
            c3.504,1.462,7.53-0.192,8.992-3.696C326.662,94.002,325.008,89.976,321.504,88.513z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M326.352,192.017h-63.496c-3.786,0-6.856,3.07-6.856,6.856c0,3.786,3.07,6.856,6.856,6.856h63.496
            c3.786,0,6.856-3.07,6.856-6.856S330.138,192.017,326.352,192.017z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M198.856,192.473c-38.881,0-70.4,31.519-70.4,70.4c0.04,38.864,31.536,70.36,70.4,70.4c38.881,0,70.4-31.519,70.4-70.4
            S237.737,192.473,198.856,192.473z M198.856,320.473c-31.812,0-57.6-25.788-57.6-57.6c0.035-31.797,25.803-57.565,57.6-57.6
            c31.812,0,57.6,25.788,57.6,57.6C256.456,294.685,230.668,320.473,198.856,320.473z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M390.856,214.873c-42.4,0-88,10.016-88,32v192c0,21.984,45.6,32,88,32c42.4,0,88-10.016,88-32v-192
            C478.856,224.889,433.256,214.873,390.856,214.873z M462.856,438.753c-1.208,4.44-25.2,16.12-72,16.12s-70.792-11.68-72-16
            v-12.576c17.024,8.576,45.144,12.576,72,12.576c26.856,0,54.984-4.04,72-12.584V438.753z M462.856,406.753
            c-1.208,4.44-25.2,16.12-72,16.12s-70.792-11.68-72-16v-12.576c17.024,8.576,45.144,12.576,72,12.576
            c26.856,0,54.984-4.04,72-12.584V406.753z M462.856,374.753c-1.208,4.44-25.2,16.12-72,16.12s-70.792-11.68-72-16v-12.576
            c17.024,8.576,45.144,12.576,72,12.576c26.856,0,54.984-4.04,72-12.584V374.753z M462.856,342.753
            c-1.208,4.44-25.2,16.12-72,16.12s-70.792-11.68-72-16v-12.576c17.024,8.576,45.144,12.576,72,12.576
            c26.856,0,54.984-4.04,72-12.584V342.753z M462.856,310.753c-1.208,4.44-25.2,16.12-72,16.12s-70.792-11.68-72-16v-12.576
            c17.024,8.536,45.144,12.576,72,12.576c26.856,0,54.984-4.04,72-12.584V310.753z M462.856,278.753
            c-1.208,4.44-25.2,16.12-72,16.12s-70.792-11.68-72-16v-12.576c17.024,8.536,45.144,12.576,72,12.576
            c26.856,0,54.984-4.04,72-12.584V278.753z M390.856,262.873c-46.728,0-70.712-11.648-72-15.856v-0.048
            c1.288-4.456,25.272-16.096,72-16.096c46.4,0,70.4,11.472,72,16C461.256,251.401,437.256,262.873,390.856,262.873z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M134.856,320.009H74.384l-28.672-31.36v-52l31.664-30.92h57.48c3.786,0,6.856-3.07,6.856-6.856
            c0-3.786-3.07-6.856-6.856-6.856H74.592c-1.792-0.004-3.515,0.694-4.8,1.944l-35.736,34.856c-1.335,1.56-2.067,3.547-2.064,5.6
            v56.896c0,1.711,0.639,3.36,1.792,4.624l32.504,35.552c1.299,1.422,3.137,2.233,5.064,2.232h63.504
            c3.786,0,6.856-3.07,6.856-6.856C141.712,323.079,138.643,320.009,134.856,320.009z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M202.856,254.873h-8c-2.488,0-4-1.392-4-2c0-0.608,1.512-2,4-2h20c4.418,0,8-3.582,8-8s-3.582-8-8-8h-8
            c0-4.418-3.582-8-8-8s-8,3.582-8,8v0.36c-8.873,1.253-15.595,8.648-16,17.6c0.573,10.489,9.507,18.548,20,18.04h8
            c2.488,0,4,1.392,4,2c0,0.608-1.512,2-4,2h-20c-4.418,0-8,3.582-8,8s3.582,8,8,8h8c0,4.418,3.582,8,8,8s8-3.582,8-8v-0.36
            c8.873-1.253,15.595-8.648,16-17.6C222.283,262.424,213.349,254.365,202.856,254.873z"/>
        </g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      </svg>
    </div>
  );
}
export default MoneySvg;